import React, { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CommonLoading } from 'react-loadingg';
import { TextField } from '@material-ui/core';

import SimpleModal from 'src/Components/SimpleModal';
import { Address, AddressUtils } from 'src/Models/Address';
import AddressMap from './AddressMap';
import useViaCep from './useViaCep';

interface ModalAdressProps extends WithTranslation {
  data: Address;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (address: Address) => void;
}

interface AddressInputProps extends Address {
  neighborhood: string;
  geoPoint: any;
  street: string;
  city: any;
}

const ModalAddressForm = ({
  t,
  data,
  isOpen,
  onClose,
  onConfirm,
}: ModalAdressProps) => {
  const [address, setAddress] = useState<AddressInputProps>({
    street: '',
    number: '',
    neighborhood: '',
    zipcode: '',
    complement: '',
    geoPoint: null,
    city: null,
    district: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [setCep, viaCepObj, cepIsLoading, cepError] = useViaCep('');

  useEffect(() => {
    if (isOpen) {
      setAddress({ ...address, ...data });
      setLoading(false);
    }
  }, [isOpen]);

  const handleCepSuccess = async () => {
    const newAddress = await AddressUtils.fromViaCep(address, viaCepObj);
    const geoPoint = await AddressUtils.getGeoPointFromGoogle(newAddress);
    setAddress({
      ...address,
      ...newAddress,
      geoPoint,
    });
  };

  useEffect(() => {
    if (!cepIsLoading && !cepError && isOpen) {
      handleCepSuccess();
    }
  }, [cepIsLoading]);

  const handleChange = (e) => {
    const { name = '', value = '' } = e?.target;
    if (name == 'zipcode') {
      const digits = value?.replace(/\D/g, '')?.slice?.(0, 8);
      setCep(digits);
      setAddress((prev) => ({ ...prev, [name]: digits }));
    } else {
      setAddress((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault?.();
    onConfirm(address);
    setLoading(true);
  };

  const handleClose = () => {
    if (!loading) {
      onClose?.();
      setAddress({ ...address, ...data });
    }
  };

  const handleChangeMapAddress = (newAddress: Address) => {
    setAddress({ ...address, ...newAddress });
  };

  return (
    <SimpleModal
      confirmEnabled
      isOpen={isOpen}
      onCancel={handleClose}
      onConfirm={handleSubmit}
      title={t('cards.business.address')}
      labelCancel={t('modalOptions.cancel')}
      labelConfirm={t('modalOptions.save')}
      onToggle={handleClose}>
      <div className="address-inputs-container">
        <div className="address-input address-input--t1">
          {cepIsLoading ? (
            <TextField
              className="BusinessCard--input"
              label={t('location.zip')}
              value="carregando..."
              name="zipcode"
              disabled
            />
          ) : (
            <>
              <TextField
                className="BusinessCard--input"
                label={t('location.zip')}
                value={address.zipcode}
                name="zipcode"
                onChange={handleChange}
              />
              {cepError && (
                <p className="input-error">{t('location.zipError')}</p>
              )}
            </>
          )}
        </div>

        <div className="address-input address-input--t2">
          <TextField
            label={t('location.street')}
            name="street"
            className="BusinessCard--input"
            value={address.street}
            onChange={handleChange}
          />
        </div>

        <div className="address-input address-input--t3">
          <TextField
            label={t('location.number')}
            className="BusinessCard--input"
            name="number"
            value={address.number}
            onChange={handleChange}
          />
        </div>

        <div className="address-input address-input--t4">
          <TextField
            label={t('location.neighboorhood')}
            className="BusinessCard--input"
            name="district"
            value={address.district}
            onChange={handleChange}
          />
        </div>

        <div className="address-input address-input--t5">
          <TextField
            label={t('location.city')}
            className="BusinessCard--input"
            name="city"
            disabled
            value={
              address?.city
                ? `${address?.city?.name}/${address?.city?.state?.acronym}`
                : ''
            }
          />
        </div>

        <div className="address-input address-input--t4">
          <TextField
            label={t('location.complement')}
            className="BusinessCard--input"
            name="complement"
            onChange={handleChange}
            value={
              address?.complement
                ? address?.complement
                : ''
            }
          />
        </div>

      </div>
      {loading === true ? (
        <div className="loading">
          <CommonLoading style={{ position: 'static', marginInline: 'auto' }} />
        </div>
      ) : (
        <AddressMap address={address} onChange={handleChangeMapAddress} />
      )}
    </SimpleModal>
  );
};

export default withTranslation()(ModalAddressForm);

import { Company } from 'src/Models/Company';
import { CompanyWallet } from 'src/Models/CompanyWallet';
import { PageableResponse } from 'src/Models/pageable';
import { action } from 'typesafe-actions';
import { CompanyActionTypes, UpdateCompanyTarget } from './types';
import { Dayjs } from 'dayjs';
import { Billing, CompanyBillingInvoice } from 'src/Models/Billing';
import { ICompanyReport } from 'src/Services/Api/CompanyReportApi';

export const getWalletById = (id: number, date: Dayjs) =>
  action(CompanyActionTypes.GET_WALLET_BY_ID, { id, date });
export const getWalletByIdSuccess = (companyWallet: CompanyWallet) =>
  action(CompanyActionTypes.GET_WALLET_BY_ID_SUCCESS, companyWallet);
export const getWalletByIdError = (message: string) =>
  action(CompanyActionTypes.GET_WALLET_BY_ID_ERROR, message);

export const getCompanyExtract = (
  id: number,
  page: number,
  size?: number,
  sort?: boolean,
  nameOrEmail?: string,
  startDate?: string,
  endDate?: string,
) =>
  action(CompanyActionTypes.GET_COMPANY_EXTRACT, {
    id,
    page,
    size,
    nameOrEmail,
    sort,
    startDate,
    endDate,
  });
export const getCompanyExtractSuccess = (page: PageableResponse<Billing>) =>
  action(CompanyActionTypes.GET_COMPANY_EXTRACT_SUCCESS, page);
export const getCompanyExtractError = (message: string) =>
  action(CompanyActionTypes.GET_COMPANY_EXTRACT_ERROR, message);

export const updateCompanyData = (
  company: Company,
  type: UpdateCompanyTarget,
) => action(CompanyActionTypes.UPDATE_COMPANY, { company, type });
export const updateCompanyDataSuccess = () =>
  action(CompanyActionTypes.UPDATE_COMPANY_SUCCESS);
export const updateCompanyDataError = (message: string) =>
  action(CompanyActionTypes.UPDATE_COMPANY_ERROR, message);

export const getCompanyReports = (pageable: { page: number; size: number }) =>
  action(CompanyActionTypes.GET_COMPANY_REPORTS, { pageable });
export const getCompanyReportsSuccess = (
  page: PageableResponse<ICompanyReport>,
) => action(CompanyActionTypes.GET_COMPANY_REPORTS_SUCCESS, page);
export const getCompanyReportsError = (message: string) =>
  action(CompanyActionTypes.GET_COMPANY_REPORTS_ERROR, message);

export const companyWalletReset = () =>
  action(CompanyActionTypes.COMPANY_WALLET_RESET);
export const companyUpdateReset = () =>
  action(CompanyActionTypes.UPDATE_COMPANY_RESET);

export const getInvoices = (companyId: number, page: number, size: number) =>
  action(CompanyActionTypes.GET_INVOICES, { companyId, page, size });
export const getInvoicesSuccess = (page: PageableResponse<CompanyBillingInvoice>) =>
  action(CompanyActionTypes.GET_INVOICES_SUCCESS, page);
export const getInvoicesError = (message: string) =>
  action(CompanyActionTypes.GET_INVOICES_ERROR, message);

export const getBankSlipPdf = (companyId: number, billingId: number) =>
  action(CompanyActionTypes.GET_BANK_SLIP_PDF, { companyId, billingId });
export const getBankSlipPdfSuccess = (pdf: string) =>
  action(CompanyActionTypes.GET_BANK_SLIP_PDF_SUCCESS, pdf);
export const getBankSlipPdfError = (message: string) =>
  action(CompanyActionTypes.GET_BANK_SLIP_PDF_ERROR, message);

export const resetBankSlipPdf = () =>
  action(CompanyActionTypes.RESET_BANK_SLIP_PDF);

import BusinessCompany from 'src/Services/Api/BusinessCompany';
import { GeoPoint } from './GeoPoint';
import { IImage } from './IImage';

export interface Address {
  id?: number;
  street?: string;
  number?: string;
  zipcode?: string;
  district?: string;
  complement?: string;
  geolocated?: boolean;
  geoPoint?: GeoPoint;
  city?: City;
  image?: IImage;
}

export const defaultValue: Readonly<Address> = {
  geolocated: false
};

export interface City {
  id?: number;
  name?: string;
  active?: boolean;
  state?: State;
}

export interface State {
  id?: number;
  name?: string;
  acronym?: string;
  active?: boolean;
}

export class AddressUtils {

  static toString(adress: Address): string {
    return "";
  }
  
  static async fromViaCep(address: Address, cepObj: any) {
    const currentCity = (
      await BusinessCompany.getCityByName({
        name: cepObj?.localidade,
        state: {
          acronym: cepObj?.uf,
        },
      })
    )?.data?.content?.[0];
    return {
      id: address?.id,
      city: currentCity,
      number: '',
      zipcode: cepObj?.cep?.replace?.(/\D/g, ''),
      district: cepObj?.bairro,
      street: cepObj?.logradouro,
      complement: cepObj?.complemento,
    };
  }

  static async getGeoPointFromGoogle(newAddress: Address) {
    const response = await BusinessCompany.getAddressWithGeoPoint(this.format(newAddress));

    const geopoint = response.data[0]?.geoPoint;
    if (!geopoint) return null;
    return geopoint;
  }

  static format(address: Address) {
    const values = [
      address?.street,
      address?.number,
      address?.complement,
      address?.district,
      address?.city?.name,
      address?.city?.state?.name,
      address?.zipcode,
    ].filter((it) => it != null && it != '');

    return values.join(', ');
  }

  static builderGeoPoint(lat: any, lng: any): GeoPoint {
    return {
      latitude: lat ?? '',
      longitude: lng ?? '',
      point: {
        coordinates: [lng ?? '', lat ?? ''],
        type: 'Point',
      },
    };
  }
}


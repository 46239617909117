import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';

const InvoiceTableHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="historic">
      <div className="date" style={{ display: 'flex', justifyContent: 'center' }}>{t('company.wallet.table.date')}</div>
      <Col className="price" style={{ display: 'flex', justifyContent: 'center' }}>{t('company.wallet.table.value')}</Col>
      <Col>
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          {t('company.wallet.table.status')}
        </span>
      </Col>
      <Col>
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          {t('company.wallet.table.bankSlipPDF')}
        </span>
      </Col>
      <Col>
        <span style={{ display: 'flex', justifyContent: 'center' }}>
          {t('company.wallet.table.NfPDF')}
        </span>
      </Col>
    </div>
  );
};

export default InvoiceTableHeader;

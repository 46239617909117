import { AxiosResponse } from 'axios';
import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { TaxInvoiceActionTypes } from './types';
import {
  getTaxInvoicePDF,
  getTaxInvoicePDFSuccess,
  getTaxInvoicePDFError,
} from './action';
import { TaxInvoiceApi } from 'src/Services/Api/TaxInvoiceApi';
import { NFSe } from 'src/Models/NFSe';


function* handleGetTaxInvoicePDF(action: ReturnType<typeof getTaxInvoicePDF>) {
  try {
    const { companyId, billingId } = action.payload;

    const result: AxiosResponse<NFSe> = yield call(
      TaxInvoiceApi.getTaxInvoicePDF,
      companyId,
      billingId
    );

    if (result.status === 200 && result.data !== null) {
      yield put(getTaxInvoicePDFSuccess(result?.data));
    }
  } catch (error) {
    if (error instanceof Error && error.stack) {
      console.error(error.stack);
      yield put(getTaxInvoicePDFError(error.stack));
    } else {
      console.error('Um erro inesperado aconteceu');
      yield put(getTaxInvoicePDFError('Um erro inesperado aconteceu'));
    }
  }
}


function* taxInvoiceSaga() {
  yield all([
    yield takeLatest(
      TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF,
      handleGetTaxInvoicePDF,
    ),
  ]);
}

export default taxInvoiceSaga;

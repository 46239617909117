import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { IRootState } from 'src/Stores';
import { RotateCircleLoading } from 'react-loadingg';
import Pagination from '../Pagination';
import Invoice from './components/InvoiceTable/invoice';
import InvoiceTableHeader from './components/InvoiceTable/InvoiceTableHeader';
import { getInvoices } from 'src/Stores/company/action';

import './styles.scss';

export interface InvoicesProps
  extends WithTranslation,
  DispatchProps,
  StateProps { }


const Invoices = ({
  invoicesPage,
  getInvoicesStatus,
  getInvoicesProps,
  t,
  companyId
}) => {
  const [page, setPage] = React.useState<number>(1);

  React.useEffect(() => {
    getInvoicesProps(companyId, page - 1, 10);
  }, [page]);

  // const mockInvoicesPage = {
  //   content: [
  //     { id: 1, value: 150.25, date: "2024-01-01", status: "WAITING_COMPANY_PAY" },
  //     { id: 2, value: 300.00, date: "2024-01-05", status: "PAID" },
  //     { id: 3, value: 200.50, date: "2024-01-10", status: "PAID" },
  //     { id: 4, value: 450.75, date: "2024-01-15", status: "PAID" },
  //     { id: 5, value: 100.00, date: "2024-01-20", status: "PAID" },
  //     { id: 6, value: 375.50, date: "2024-01-25", status: "PAID" },
  //     { id: 7, value: 80.00, date: "2024-02-01", status: "PAID" },
  //     { id: 8, value: 520.30, date: "2024-02-05", status: "PAID" },
  //     { id: 9, value: 95.99, date: "2024-02-10", status: "PAID" },
  //     { id: 10, value: 640.80, date: "2024-02-15", status: "PAID" },
  //   ],
  //   pageable: {
  //     sort: {
  //       sorted: true,
  //       unsorted: false,
  //       empty: false,
  //     },
  //     pageNumber: 0,
  //     pageSize: 10,
  //     offset: 0,
  //     paged: true,
  //     unpaged: false,
  //   },
  //   totalElements: 10,
  //   totalPages: 18,
  //   last: true,
  //   size: 10,
  //   number: 0,
  //   sort: {
  //     sorted: true,
  //     unsorted: false,
  //     empty: false,
  //   },
  //   first: true,
  //   numberOfElements: 10,
  //   empty: false,
  // };


  return (
    <div className="invoices">
      <div className="table">
        <InvoiceTableHeader />
        {getInvoicesStatus === HttpRequestStatus.ONGOING ? (
          <div style={{ width: '100%', height: '100%' }}>
            <RotateCircleLoading
              size="large"
              style={{
                position: 'static',
                marginInline: 'auto',
                marginBlock: '100px',
              }}
            />
          </div>
        ) : invoicesPage?.content?.length > 0 ? (
          invoicesPage?.content?.map((it) => <Invoice billing={it} key={it.id} />)
        ) : (
          <div className="no-data">
            <p>{t('wallet.extract.noData')}</p>
          </div>
        )}
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        allPages={invoicesPage?.totalPages ?? 1}
      />
    </div>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    invoicesPage: state.companyState.invoicesPage,
    getInvoicesStatus: state.companyState.getInvoicesStatus,
    companyId: state.authentication.companyUser.company?.id,
  };
};

const mapDispatchToProps = {
  getInvoicesProps: getInvoices,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Invoices),
);

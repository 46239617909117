import { Reducer } from 'redux';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { TaxInvoiceActionTypes, TaxInvoiceState } from './types';
import { ActionType } from 'typesafe-actions';
import * as taxInvoiceAction from './action';

export type TaxInvoiceAction = ActionType<typeof taxInvoiceAction>;

export const initialState: TaxInvoiceState = {
  taxInvoice: null,
  getTaxInvoiceStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<TaxInvoiceState> = (
  state = initialState,
  action: TaxInvoiceAction,
) => {
  switch (action.type) {
    case TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF: {
      return { ...state, bankSlipPdfStatus: HttpRequestStatus.ONGOING };
    }

    case TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF_SUCCESS: {
      const nfPDF = action?.payload;

      return {
        ...state,
        taxInvoice: nfPDF,
        getTaxInvoiceStatus: HttpRequestStatus.SUCCESS,
      };
    }

    case TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF_ERROR: {
      return {
        ...state,
        getTaxInvoiceStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case TaxInvoiceActionTypes.RESET_TAX_INVOICE_PDF: {
      return {
        ...state,
        taxInvoice: null,
        getTaxInvoiceStatus: HttpRequestStatus.NOOP,
      };
    }

    default: {
      return state;
    }
  };
}

export { reducer as taxInvoiceReducer };

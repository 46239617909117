import { Billing, CompanyBillingInvoice } from 'src/Models/Billing';
import { CompanyWallet } from 'src/Models/CompanyWallet';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { PageableResponse } from 'src/Models/pageable';
import { ICompanyReport } from 'src/Services/Api/CompanyReportApi';

export enum UpdateCompanyTarget {
  COMPANY_DATA = 'COMPANY_DATA',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  COMPANY_OWNER = 'COMPANY_OWNER',
}

export enum CompanyActionTypes {
  GET_WALLET_BY_ID = '@@companyAction/GET_WALLET_BY_ID',
  GET_WALLET_BY_ID_SUCCESS = '@@companyAction/GET_WALLET_BY_ID_SUCCESS',
  GET_WALLET_BY_ID_ERROR = '@@companyAction/GET_WALLET_BY_ID_ERROR',

  GET_COMPANY_EXTRACT = '@@companyAction/GET_COMPANY_EXTRACT',
  GET_COMPANY_EXTRACT_SUCCESS = '@@companyAction/GET_COMPANY_EXTRACT_SUCCESS',
  GET_COMPANY_EXTRACT_ERROR = '@@companyAction/GET_COMPANY_EXTRACT_ERROR',

  GET_COMPANY_REPORTS = '@@companyAction/GET_COMPANY_REPORTS',
  GET_COMPANY_REPORTS_SUCCESS = '@@companyAction/GET_COMPANY_REPORTS_SUCCESS',
  GET_COMPANY_REPORTS_ERROR = '@@companyAction/GET_COMPANY_REPORTS_ERROR',

  UPDATE_COMPANY = '@@companyAction/UPDATE_COMPANY',
  UPDATE_COMPANY_SUCCESS = '@@companyAction/UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_ERROR = '@@companyAction/UPDATE_COMPANY_ERROR',

  COMPANY_WALLET_RESET = '@@companyWallet/COMPANY_WALLET_RESET',
  RESET_COMPANY_EXTRACT = '@@companyAction/RESET_COMPANY_EXTRACT',
  UPDATE_COMPANY_RESET = '@@companyAction/UPDATE_COMPANY_RESET',

  GET_INVOICES = '@@companyAction/GET_INVOICES',
  GET_INVOICES_SUCCESS = '@@companyAction/GET_INVOICES_SUCCESS',
  GET_INVOICES_ERROR = '@@companyAction/GET_INVOICES_ERROR',

  GET_BANK_SLIP_PDF = '@@companyAction/GET_BANK_SLIP_PDF',
  GET_BANK_SLIP_PDF_SUCCESS = '@@companyAction/GET_BANK_SLIP_PDF_SUCCESS',
  GET_BANK_SLIP_PDF_ERROR = '@@companyAction/GET_BANK_SLIP_PDF_ERROR',

  RESET_BANK_SLIP_PDF = '@@companyAction/RESET_BANK_SLIP_PDF',
}

export interface CompanyState {
  readonly status: HttpRequestStatus;
  readonly updateStatus: HttpRequestStatus;
  readonly getReportsStatus: HttpRequestStatus;
  readonly companyWallet?: CompanyWallet;
  readonly billingPage?: PageableResponse<Billing>;
  readonly reportsPage: PageableResponse<ICompanyReport>;
  readonly invoicesPage: PageableResponse<CompanyBillingInvoice>;
  readonly getInvoicesStatus: HttpRequestStatus;
  readonly bankSlipPdf?: string;
  readonly bankSlipPdfStatus?: string;
}

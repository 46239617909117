import { Reducer } from 'redux';
import { CompanyWallet } from 'src/Models/CompanyWallet';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { CompanyState, CompanyActionTypes } from './types';
import { ActionType } from 'typesafe-actions';
import * as companyAction from './action';

export type CompanyAction = ActionType<typeof companyAction>;

export const initialState: CompanyState = {
  getReportsStatus: HttpRequestStatus.NOOP,
  status: HttpRequestStatus.NOOP,
  updateStatus: HttpRequestStatus.NOOP,
  reportsPage: undefined,
  invoicesPage: undefined,
  getInvoicesStatus: HttpRequestStatus.NOOP,
  bankSlipPdf: undefined,
  bankSlipPdfStatus: HttpRequestStatus.NOOP,
};

const reducer: Reducer<CompanyState> = (
  state = initialState,
  action: CompanyAction,
) => {
  switch (action.type) {
    case CompanyActionTypes.GET_WALLET_BY_ID: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }

    case CompanyActionTypes.GET_WALLET_BY_ID_SUCCESS: {
      const companyWallet = action?.payload as CompanyWallet;

      return { ...state, companyWallet, status: HttpRequestStatus.SUCCESS };
    }

    case CompanyActionTypes.GET_WALLET_BY_ID_ERROR: {
      return {
        ...state,
        status: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }
    case CompanyActionTypes.UPDATE_COMPANY: {
      return { ...state, updateStatus: HttpRequestStatus.ONGOING };
    }

    case CompanyActionTypes.UPDATE_COMPANY_SUCCESS: {
      return { ...state, updateStatus: HttpRequestStatus.SUCCESS };
    }

    case CompanyActionTypes.UPDATE_COMPANY_ERROR: {
      return {
        ...state,
        updateStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case CompanyActionTypes.COMPANY_WALLET_RESET: {
      return { ...state, status: HttpRequestStatus.NOOP };
    }

    case CompanyActionTypes.UPDATE_COMPANY_RESET: {
      return { ...state, updateStatus: HttpRequestStatus.NOOP };
    }

    case CompanyActionTypes.GET_COMPANY_EXTRACT: {
      return { ...state, status: HttpRequestStatus.ONGOING };
    }

    case CompanyActionTypes.GET_COMPANY_EXTRACT_SUCCESS: {
      const billingPage = action?.payload;

      return { ...state, billingPage, status: HttpRequestStatus.SUCCESS };
    }

    case CompanyActionTypes.GET_COMPANY_EXTRACT_ERROR: {
      return {
        ...state,
        status: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case CompanyActionTypes.GET_COMPANY_REPORTS: {
      return { ...state, getReportsStatus: HttpRequestStatus.ONGOING };
    }

    case CompanyActionTypes.GET_COMPANY_REPORTS_SUCCESS: {
      const reportsPage = action?.payload;

      return {
        ...state,
        reportsPage,
        getReportsStatus: HttpRequestStatus.SUCCESS,
      };
    }

    case CompanyActionTypes.GET_COMPANY_REPORTS_ERROR: {
      return {
        ...state,
        getReportsStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case CompanyActionTypes.GET_INVOICES: {
      return { ...state, getInvoicesStatus: HttpRequestStatus.ONGOING };
    }

    case CompanyActionTypes.GET_INVOICES_SUCCESS: {
      const invoicesPage = action?.payload;

      return {
        ...state,
        invoicesPage,
        getInvoicesStatus: HttpRequestStatus.SUCCESS,
      };
    }

    case CompanyActionTypes.GET_INVOICES_ERROR: {
      return {
        ...state,
        getInvoicesStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case CompanyActionTypes.GET_BANK_SLIP_PDF: {
      return { ...state, bankSlipPdfStatus: HttpRequestStatus.ONGOING };
    }

    case CompanyActionTypes.GET_BANK_SLIP_PDF_SUCCESS: {
      const bankSlipPdf = action?.payload;

      return {
        ...state,
        bankSlipPdf,
        bankSlipPdfStatus: HttpRequestStatus.SUCCESS,
      };
    }

    case CompanyActionTypes.GET_BANK_SLIP_PDF_ERROR: {
      return {
        ...state,
        bankSlipPdfStatus: HttpRequestStatus.ERROR,
        error: action?.payload,
      };
    }

    case CompanyActionTypes.RESET_BANK_SLIP_PDF: {
      return {
        ...state,
        bankSlipPdf: null,
        bankSlipPdfStatus: undefined,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as companyWalletReducer };

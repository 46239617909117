import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { NFSe } from 'src/Models/NFSe';

export enum TaxInvoiceActionTypes {
  GET_TAX_INVOICE_PDF = '@@taxInvoiceAction/GET_TAX_INVOICE_PDF',
  GET_TAX_INVOICE_PDF_SUCCESS = '@@taxInvoiceAction/GET_TAX_INVOICE_PDF_SUCCESS',
  GET_TAX_INVOICE_PDF_ERROR = '@@taxInvoiceAction/GET_TAX_INVOICE_PDF_ERROR',

  RESET_TAX_INVOICE_PDF = '@@taxInvoiceAction/RESET_TAX_INVOICE_PDF',
}

export interface TaxInvoiceState {
  taxInvoice: null | NFSe,
  getTaxInvoiceStatus: HttpRequestStatus,
}

import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col } from 'reactstrap';
import { CompanyBillingInvoice } from 'src/Models/Billing';
import { IRootState } from 'src/Stores';
import './styles.scss';
import { getBankSlipPdf, resetBankSlipPdf } from 'src/Stores/company/action';
import { Modal, Box, Button } from '@mui/material';
import { HttpRequestStatus } from 'src/Models/Enums/HttpRequestStatus';
import { RotateCircleLoading } from 'react-loadingg';
import { getTaxInvoicePDF, resetTaxInvoice } from 'src/Stores/taxInvoice/action';

enum PDFType {
  BANK_SLIP = 'BANK_SLIP',
  TAX_INVOICE = 'TAX_INVOICE',
}

interface InvoicesTableProps
  extends WithTranslation,
  DispatchProps,
  StateProps {
  billing: CompanyBillingInvoice;
}

const Invoice = ({ billing, t, getBankSlipPdfProps, bankSlipPdfStatus, getTaxInvoicePDFProps, getTaxInvoiceStatus, taxInvoice, bankSlipPdf, resetTaxInvoiceProps, companyId }: InvoicesTableProps) => {
  const [showModal, setShowModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<{ id: number | null; type: PDFType.BANK_SLIP | PDFType.TAX_INVOICE | null }>({ id: null, type: null });

  const dispatch = useDispatch();

  const { date, status, value } = billing;

  React.useEffect(() => {
    if (bankSlipPdf) {
      setPdfUrl(bankSlipPdf);
      setShowModal(true);
      setLoading({ id: null, type: null });
    }
  }, [bankSlipPdf]);

  React.useEffect(() => {
    if (bankSlipPdfStatus === HttpRequestStatus.ERROR || bankSlipPdfStatus === HttpRequestStatus.SUCCESS) {
      setLoading({ id: null, type: null });
    }
  }, [bankSlipPdfStatus]);

  React.useEffect(() => {
    if (getTaxInvoiceStatus === HttpRequestStatus.ERROR || getTaxInvoiceStatus === HttpRequestStatus.SUCCESS) {
      setLoading({ id: null, type: null });
    }

    if (getTaxInvoiceStatus === HttpRequestStatus.SUCCESS && taxInvoice) {
      window.open(taxInvoice?.url, '_blank');

      resetTaxInvoiceProps();
    }
  }, [getTaxInvoiceStatus]);

  const closeModal = () => {
    setShowModal(false);
    if (pdfUrl) {
      window.URL.revokeObjectURL(pdfUrl);
      setPdfUrl(null);
      dispatch(resetBankSlipPdf());
    }
  };

  const handleGetBankSlipPdf = (billingId: number) => {
    setLoading({ id: billingId, type: PDFType.BANK_SLIP });
    getBankSlipPdfProps(companyId, billingId);
  };

  const handleGetTaxInvoicePdf = (billingId: number) => {
    setLoading({ id: billingId, type: PDFType.TAX_INVOICE });
    getTaxInvoicePDFProps(companyId, billingId);
  };

  const valueFormatted = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  const formattedDate = new Date(date).toLocaleString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedHour = new Date(date).toLocaleString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });

  return (
    <>
      <div className="historic">
        <div className="date">
          <div className="hour" style={{ textAlign: 'center' }}>{formattedDate}</div>
          <div className="hour" style={{ textAlign: 'center' }}>{formattedHour}</div>
        </div>
        <Col className="price" style={{ textAlign: 'center' }}>
          <div>
            {valueFormatted}
          </div>
        </Col>
        <Col className="type">
          <div className="status-container__invoice">
            <span
              className={`status ${status === 'WAITING_COMPANY_PAY'
                ? 'pending'
                : status === 'NOT_PAID'
                  ? 'error'
                  : ''
                }`}>
              {t(`company.wallet.invoice.${status}`)}
            </span>
          </div>
        </Col>

        <Col style={{ textAlign: 'center' }}>
          {loading.id === billing.id && loading.type === PDFType.BANK_SLIP ? (
            <RotateCircleLoading size="small" />
          ) : (
            <span
              style={{
                cursor: 'pointer',
                color: '#007bff',
                borderBottom: '1px solid #007bff',
              }}
              onClick={() => { handleGetBankSlipPdf(billing.id) }}
            >
              Visualizar Boleto
            </span>
          )}
        </Col>

        <Col style={{ textAlign: 'center' }}>
          {loading.id === billing.id && loading.type === PDFType.TAX_INVOICE ? (
            <RotateCircleLoading size="small" />
          ) : (
            <span
              style={{
                cursor: 'pointer',
                color: '#007bff',
                borderBottom: '1px solid #007bff',
              }}
              onClick={() => { handleGetTaxInvoicePdf(billing.id) }}
            >
              Visualizar NF
            </span>
          )}
        </Col>

      </div>

      {/* Modal para exibir o PDF */}
      <Modal open={showModal} onClose={closeModal}>
        <Box
          sx={{
            width: '80%',
            maxWidth: '900px',
            height: 'fit-content',
            margin: 'auto',
            bgcolor: 'white',
            borderRadius: 1,
            boxShadow: 24,
            padding: 2,
          }}
        >
          <h2>Visualizar Boleto</h2>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              style={{ width: '100%', height: '80vh' }}
              frameBorder="0"
            />
          )}
          <div style={{ marginTop: '20px' }}>
            <Button variant="outlined" color="secondary" onClick={closeModal}>
              Fechar
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const link = document.createElement('a');
                link.href = pdfUrl!;
                link.download = 'boleto.pdf';
                link.click();
              }}
              style={{ marginLeft: '10px' }}
            >
              Baixar PDF
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  return {
    invoicesPage: state.companyState.invoicesPage,
    getInvoicesStatus: state.companyState.getInvoicesStatus,
    bankSlipPdf: state.companyState.bankSlipPdf,
    bankSlipPdfStatus: state.companyState.bankSlipPdfStatus,
    taxInvoice: state.taxInvoice.taxInvoice,
    getTaxInvoiceStatus: state.taxInvoice.getTaxInvoiceStatus,
    companyId: state.authentication.companyUser.company?.id,
  };
};

const mapDispatchToProps = {
  getBankSlipPdfProps: getBankSlipPdf,
  getTaxInvoicePDFProps: getTaxInvoicePDF,
  resetTaxInvoiceProps: resetTaxInvoice,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Invoice),
);

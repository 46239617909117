import { api } from '.';
const baseUrl = '/tax-invoices';

export class TaxInvoiceApi {

  static getTaxInvoicePDF = (companyId: number, billingId: number) => {
    return api.get(`${baseUrl}/company/${companyId}/billing/${billingId}/pdf`, {
      responseType: 'blob',
    });
  }

}

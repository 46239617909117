import { action } from 'typesafe-actions';
import { TaxInvoiceActionTypes } from './types';
import { NFSe } from 'src/Models/NFSe';

export const getTaxInvoicePDF = (companyId: number, billingId: number) =>
  action(TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF, { companyId, billingId });
export const getTaxInvoicePDFSuccess = (pdf: NFSe) =>
  action(TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF_SUCCESS, pdf);
export const getTaxInvoicePDFError = (message: string) =>
  action(TaxInvoiceActionTypes.GET_TAX_INVOICE_PDF_ERROR, message);

export const resetTaxInvoice = () =>
  action(TaxInvoiceActionTypes.RESET_TAX_INVOICE_PDF);
